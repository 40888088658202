import { createSlice } from "@reduxjs/toolkit";

const gameEndedSlice = createSlice({
  name: "gameEnded",
  initialState: {
    state: false,
    raccoonPosition: 0,
    raccoonIndex: 0,
    gameEndedMp: 0
  },
  reducers: {
    setGameEnded(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setGameEnded } = gameEndedSlice.actions;
export default gameEndedSlice.reducer;
