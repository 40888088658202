import store from '../../store/configure-store';


/**
 * customizationParser
 * 
 * @param {string} login 
 * @returns 
 */
export const customizationParser = () => {
  const {login} = store.getState('login');
  const customization = login?.Customization;
  
  if (!customization) {
    return;
  }
  let parserdData;
  try {
    parserdData = JSON.parse(customization);
    return parserdData;
  } catch (error) {
    console.error("COULD NOT parse config");
    return null;
  }
};
