import { createSlice } from '@reduxjs/toolkit';

const jackpotSlice = createSlice({
  name: 'jackpot',
  initialState: {
    value: 0
  },
  reducers: {
    setJackpotValue: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { setJackpotValue } = jackpotSlice.actions;
export default jackpotSlice.reducer;
