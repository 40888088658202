import store from "../../store/configure-store";
import { setBalanceValue } from "../../store/entities/balance-slice";
import { toggleFreeBetsState } from "../../store/entities/free-bets-state-slice";
import { setFreeTicketInfo } from "../../store/entities/freetickets/free-ticket-info-slice";
import { setJackpotValue } from "../../store/entities/jackpot-slice";
import { setAlreadyShownBonusModal, setBounsRoundsModal } from "../../store/entities/modals/bonus-rounds-modal-slice";
import { shouldShowBonusModal } from "../bonuses/bounus-modal";
import { setsProperBonusLabel } from "../free-tickets/show-bonus-label";

/**
 * RaccoonTransport
 */
class RaccoonTransport {

  /**
   * constructor
   * @param {*} params 
   * @returns 
   */
  constructor(params) {
    if (RaccoonTransport.instance == null) {
      // params
      RaccoonTransport.instance = this;
    }

    return RaccoonTransport.instance;
  }

  /**
   * Sets balance to store
   * @param {*} balance 
   */
  balance(balance) {
    store.dispatch(setBalanceValue(balance));
  }

  /**
   * Sets jackpot data to store
   * @param {*} jackpot 
   */
  jackpot(jackpot) {
    store.dispatch(setJackpotValue(jackpot));
  }

  /**
   * Logic for clearing expired bonus rounds
   * 
   * @param {*} value 
   */
  bonusExpired(value) {
    console.log("value", value);
    if (value) {
      console.log("store.getState.freeBet", store.getState().freeBet);
      if (store.getState().freeBet) {
        store.dispatch(setFreeTicketInfo({
          ...store.getState().freeTicketInfo,
          freeTicketCount:1 }));
      } else {
        store.dispatch(toggleFreeBetsState(false));
        setsProperBonusLabel(false);
        store.dispatch(setFreeTicketInfo({
          noOfRounds: 0,
          value: 0,
          currency: '',
          freeTicketCount: 0,
          validUntil: null
        }));
        store.dispatch(setAlreadyShownBonusModal(false));
      }
    }
  }

  /**
   * sets freeTickets (bonus) to store
   * @param {*} data 
   */
  freeTickets(data) {
    const parts = data?.message?.MessageText.split(';');

    // Extract values from the parts
    const bonusRounds = Number(parts[0]); // First part is the bonusRounds
    const [amount, currency] = parts[1].split(' '); // Second part contains the amount and currency
    const timestamp = (parts.length === 3 && /\d/.test(parts[2].trim())) ? parts[2].trim() : null;

    // Convert amount to a number
    const amountValue = parseFloat(amount);
    if (shouldShowBonusModal(currency)) {
      store.dispatch(setBounsRoundsModal({
        show:true,
        noOfRounds: Number(bonusRounds),
        validUntil: timestamp,
        value: amountValue,
        currency,
        alreadyShown: false
      }))
    }
  }
}

const racconTransport = new RaccoonTransport();
export default racconTransport;
