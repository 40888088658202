import { createSlice } from "@reduxjs/toolkit";

const raccoonMoveSlice = createSlice({
  name: "raccoonMove",
  initialState: {
    position: 0,
    firstClick: true,
    raccoonIndex: -2,
    lineIndex: 0
  },
  reducers: {
    setRacoonMove(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setRacoonMove } = raccoonMoveSlice.actions;
export default raccoonMoveSlice.reducer;
