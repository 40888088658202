export function findDifficultyByCarsAndLanes(levels, cars, lanes) {
  try {
      for (const [difficulty, details] of Object.entries(levels)) {
        if (details.Cars === cars && details.Lanes === lanes) {
            return difficulty;
        }
    }
    return null; // If no match is found
  } catch (error) {
    return null;
  }
}
