import { validateBetResponse } from "./bet-response-validator";
import { validateFreeBetResponse } from "./freebet-response-validator";
import { validateLoginResponse } from "./login-response-validator";

const responseValidators = {
  [process.env.REACT_APP_BET_ROUTE]: validateBetResponse,
  [process.env.REACT_APP_LOGIN_ROUTE]: validateLoginResponse,
  [process.env.REACT_APP_FREE_BET_ROUTE]: validateFreeBetResponse,
  // Add other routes and their corresponding validators here
};

export const validateResponse = (url, response) => {
  const validator = responseValidators[url];

  if (validator) {
    try {
      return validator(response);
    } catch (error) {
      console.error(`Error validating response for ${url}:`, error);
      // Optionally dispatch a generic error message here
      return false;
    }
  } else {
    //console.warn(`No validator found for ${url}`);
    // Optionally handle unvalidated responses here
    return true;
  }
};
