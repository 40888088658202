import { createSlice } from '@reduxjs/toolkit';

const endGameModalSlice = createSlice({
  name: 'endGameModal',
  initialState: false,
  reducers: {
    setEndGameModal: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setEndGameModal} = endGameModalSlice.actions;
export default endGameModalSlice.reducer;
