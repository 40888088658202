import { apiCallBegan } from "../api"

export const getLogin = (data) => {
  return apiCallBegan({
    url: process.env.REACT_APP_LOGIN_ROUTE,
    method: 'POST',
    data: data,
    onSuccess: 'login/postLogin'
  })
}
