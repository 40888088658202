import { setPopupMessage } from '../../store/entities/popup-message-slice';
import { setExpirationTime, updateRemainingTime } from '../../store/entities/ticket-timer-slice';
import "hacktimer"; // Import HackTimer to override setTimeout and setInterval
import store from '../../store/configure-store';
import translate from '../translation/lang';
import { takeCashout } from '../../store/entities/cashout-slice';
import { cancelBet } from '../../store/entities/cancel-slice';
import { clearBet } from '../../store/entities/bet-slice';
import { getBalance } from '../websocket-conn/get-balance';
import { clearFreeBet } from '../../store/entities/free-bet-slice';
import { increaseBonusCumulative } from '../../store/entities/freetickets/bonus-cumulative-slice';

let showPopupMessage = true;
var intervalId;

export const startTimer = (dispatch, expirationTime) => {
  clearInterval(intervalId);
  showPopupMessage = true;
  dispatch(setExpirationTime(expirationTime));
  intervalId = setInterval(() => {
    const currentTime = Date.now();
    const timeRemaining = expirationTime - currentTime;
    //console.log("time remaining", timeRemaining);
    if (timeRemaining <= 0) {
      //console.log("cleaning");
      showPopupMessage = true;
      dispatch(setPopupMessage({
        message: translate("cashout_inactivity"),
        delay: 5000
      }));
      clearInterval(intervalId);
      // ako je raccon na < 0 index onda radi cancel bet 
      // ako je na ostale indexe onda radi cashout
      const racoonIndex = store.getState().raccoonMove.raccoonIndex;
      if (racoonIndex < 0) {
        dispatch(cancelBet({
          session_id: store.getState().login.SessionID,
          ticket_id: store.getState().freeBet?.TicketID ? store.getState().freeBet?.TicketID 
            : store.getState().bet?.TicketID,
        })).then((response) => {
          const data = response?.payload;
          if (data.Status === 1) {
            dispatch(clearBet());
            dispatch(clearFreeBet());
            getBalance(dispatch);
          }
        }).catch((error) => {
          dispatch(setPopupMessage({
            message: translate("there_was_error"),
            delay: 3000
          }));
        });
      } else {
        dispatch(
          takeCashout({
            session_id: store.getState().login.SessionID,
            ticket_id: store.getState().freeBet?.TicketID ? store.getState().freeBet?.TicketID 
              : store.getState().bet?.TicketID,
          })).then((response) => {
            const data = response.payload;
            if ( store.getState().freeBet?.TicketID) {
              dispatch(increaseBonusCumulative(data?.WinMoney))
            } 

            getBalance(dispatch);
         })
         .catch((error) => {
           console.error('API call failed:', error);
         })
      }
    } else if (timeRemaining <= 30000) { // 30 seconds before expiration
      if (showPopupMessage) {
        //console.log("trigering modal");
        dispatch(setPopupMessage({
          message: translate("time_running_out"),
          delay: 10000
        }));
        showPopupMessage = false;
      }
      dispatch(updateRemainingTime(Math.floor(timeRemaining / 1000)));
    }
  }, 1000);
};

export const clearTicketTimer = () => {
  clearInterval(intervalId);
  showPopupMessage = true;
}
