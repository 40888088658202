import { createSlice } from "@reduxjs/toolkit";

const healthSlice = createSlice({
  name: "health",
  initialState: null,
  reducers: {
    fetchHealth(state, action) {
      state = action.payload;

      return state;
    }
  }
});

//export const { clearAllRestaurants } = allRestaurantsSlice.actions;
export default healthSlice.reducer;
