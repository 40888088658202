import { createSlice } from "@reduxjs/toolkit";

const gameCashedOutSlice = createSlice({
  name: "gameCashedOut",
  initialState: {
    state: false,
    raccoonPosition: 0,
    raccoonIndex: 0,
    cashoutMp: 0,
    hitLane: null,
  },
  reducers: {
    setGameCashedOut(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setGameCashedOut } = gameCashedOutSlice.actions;
export default gameCashedOutSlice.reducer;
