import { createSlice } from "@reduxjs/toolkit";

const betSlice = createSlice({
  name: "bet",
  initialState: null,
  reducers: {
    postBet(state, action) {
      state = action.payload;

      return state;
    },
    clearBet(state) {
      state = null;

      return state;
    },
    setActiveBet(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { clearBet, setActiveBet } = betSlice.actions;
export default betSlice.reducer;
