import { createSlice } from '@reduxjs/toolkit';

const cumulativeBonusLabelSlice = createSlice({
  name: 'cumulativeBonusLabel',
  initialState: false,
  reducers: {
    setCumulativeBonusLabel: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setCumulativeBonusLabel} = cumulativeBonusLabelSlice.actions;
export default cumulativeBonusLabelSlice.reducer;
