import { createSlice } from "@reduxjs/toolkit";

const hotKeysSlice = createSlice({
  name: "hotKeys",
  initialState: false,
  reducers: {
    toggleHotKeys(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { toggleHotKeys } = hotKeysSlice.actions;
export default hotKeysSlice.reducer;
