import { createSlice } from '@reduxjs/toolkit';

const cumulativeWonModalSlice = createSlice({
  name: 'cumulativeWonModal',
  initialState: false,
  reducers: {
    setCumulativeWonModal: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setCumulativeWonModal } = cumulativeWonModalSlice.actions;
export default cumulativeWonModalSlice.reducer;
