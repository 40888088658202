import { createSlice } from '@reduxjs/toolkit';

export const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState: true,
  reducers: {
    setTabActive: (state) => {
      state = true;

      return state;
    },
    setTabInactive: (state) => {
      state = false;

      return state;
    }
  },
});

export const { setTabActive, setTabInactive } = activeTabSlice.actions;
export default activeTabSlice.reducer;
