import { createSlice } from "@reduxjs/toolkit";

const raccoonTakeOverSlice = createSlice({
  name: "raccoonTakeOver",
  initialState: false,
  reducers: {
    setRaccoonTakeOver(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setRaccoonTakeOver } = raccoonTakeOverSlice.actions;
export default raccoonTakeOverSlice.reducer;
