import { createSlice } from "@reduxjs/toolkit";

const gameStartedSlice = createSlice({
  name: "gameStarted",
  initialState: false,
  reducers: {
    setGameStarted(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setGameStarted } = gameStartedSlice.actions;
export default gameStartedSlice.reducer;
