import { createSlice } from "@reduxjs/toolkit";

const spinnerSlice = createSlice({
  name: "spinner",
  initialState: false,
  reducers: {
    setSpinner(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export default spinnerSlice.reducer;
