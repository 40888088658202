import { createSlice } from '@reduxjs/toolkit';

const freeTicketInfoSlice = createSlice({
  name: 'freeTicketInfo',
  initialState: {
    noOfRounds: 0,
    value: 0,
    currency: '',
    freeTicketCount: 0,
    validUntil: null
  },
  reducers: {
    setFreeTicketInfo: (state, action) => {
      state = action.payload;

      return state;
    },
    decreaseTicketCount: (state, action) => {
      if (state?.freeTicketCount > 0) {
        state.freeTicketCount = state.freeTicketCount - 1;
      }

      return state;
    }
  },
});

export const { setFreeTicketInfo, decreaseTicketCount } = freeTicketInfoSlice.actions;
export default freeTicketInfoSlice.reducer;
