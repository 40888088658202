/* eslint-disable import/no-anonymous-default-export */
export default {
  "us": {
    "gameServers": "https://server.raccoonrush.elbet.com:8104"
  },
  "retail": {
    "gameServers": "https://server.raccoonrush.elbet.com:8104"
  },
  "default": {
    "gameServers": "https://server.raccoonrush.elbet.com:8104"
  }
}