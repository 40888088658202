import store from "../../store/configure-store";

export const shouldShowBonusModal = (currency) => {

  const playerCurrency = store.getState().login.CurrencyID;

  if (store.getState()?.bounsRoundsModal?.alreadyShown) {
    return false;
  }
  if (currency.toLowerCase() !== playerCurrency.toLowerCase()) {
    return false;
  }
  return true;
}
