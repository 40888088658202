import { createSlice } from "@reduxjs/toolkit";

const gameDifficultySlice = createSlice({
  name: "gameDifficulty",
  initialState: "easy",
  reducers: {
    changeGameDifficulty(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { changeGameDifficulty } = gameDifficultySlice.actions;
export default gameDifficultySlice.reducer;
