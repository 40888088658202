import { createSlice } from '@reduxjs/toolkit';

const maxWinModalSlice = createSlice({
  name: 'maxWinModal',
  initialState: {
    show:false,
    value: 0
  },
  reducers: {
    setMaxWinModal: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setMaxWinModal} = maxWinModalSlice.actions;
export default maxWinModalSlice.reducer;
