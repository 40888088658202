import { createSlice } from "@reduxjs/toolkit";

const raccoonWalkingSlice = createSlice({
  name: "raccoonWalking",
  initialState: false,
  reducers: {
    setRaccoonWalking(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setRaccoonWalking } = raccoonWalkingSlice.actions;
export default raccoonWalkingSlice.reducer;
