import { createSlice } from "@reduxjs/toolkit";

const soundsLoadedSlice = createSlice({
  name: "soundsLoaded",
  initialState: false,
  reducers: {
    setSoundsLoaded(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setSoundsLoaded } = soundsLoadedSlice.actions;
export default soundsLoadedSlice.reducer;
