import { createSlice } from '@reduxjs/toolkit';

const autoPlayTabActiveSlice = createSlice({
  name: 'autoPlayTabActive',
  initialState: 0,
  reducers: {
    toggleAutoPlayTab: (state, action) => {
      state = action.payload;
      return state;
    }
  },
});

export const { toggleAutoPlayTab } = autoPlayTabActiveSlice.actions;
export default autoPlayTabActiveSlice.reducer;
