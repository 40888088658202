import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from '../../services/axios';

export const setPlayerSettings = createAsyncThunk(
  'playerSettings/postSetPlayerSettings',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_UPDATE_PLAYER_SETTINGS_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const playerSettingsSlice = createSlice({
  name: "playerSettings",
  initialState: {
    music_enabled:false,
    sound_enabled: false
  },
  reducers: {
    loginSetPlayerSettings(state, action) {
      state = action.payload;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPlayerSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPlayerSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.sound_enabled = action?.meta?.arg?.sound_enabled;
        state.music_enabled = action?.meta?.arg?.music_enabled;

       return state;
      })
      .addCase(setPlayerSettings.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { loginSetPlayerSettings } = playerSettingsSlice.actions;
export default playerSettingsSlice.reducer;
