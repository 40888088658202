import { createSlice } from '@reduxjs/toolkit';

const jackpotAndMaxWinModalsActiveSlice = createSlice({
  name: 'jackpotAndMaxWinModalsActive',
  initialState: false,
  reducers: {
    setJackpotAndMaxWinModalsActive: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setJackpotAndMaxWinModalsActive} = jackpotAndMaxWinModalsActiveSlice.actions;
export default jackpotAndMaxWinModalsActiveSlice.reducer;
