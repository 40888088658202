import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expirationTime: null,
  remainingTime: 0,
};

const timerSlice = createSlice({
  name: 'ticketTimer',
  initialState,
  reducers: {
    setExpirationTime: (state, action) => {
      state.expirationTime = action.payload;
    },
    updateRemainingTime: (state, action) => {
      state.remainingTime = action.payload;
    },
  },
});

export const { setExpirationTime, updateRemainingTime } = timerSlice.actions;

export default timerSlice.reducer;

