import { createSlice } from '@reduxjs/toolkit';

const jackpotModalSlice = createSlice({
  name: 'jackpotModal',
  initialState: {
    show:false,
    value: 0
  },
  reducers: {
    setJackpotModal: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setJackpotModal} = jackpotModalSlice.actions;
export default jackpotModalSlice.reducer;
