import { createSlice } from '@reduxjs/toolkit';

const winningStrikeSlice = createSlice({
  name: 'winningStrike',
  initialState: {
    value: 0,
    sessionId: null
  },
  reducers: {
    setWinningStrike: (state, action) => {
      state = action.payload;

      return state;
    },
    resetWinningStrike: (state) => {
      state = {
        value: 0,
        sessionId: null
      };

      return state;
    }
  },
});

export const { resetWinningStrike, setWinningStrike } = winningStrikeSlice.actions;
export default winningStrikeSlice.reducer;
